/* index.css */
.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.6;
  }
  .modal {
    width: 30vw;
    height: fit-content;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color:#000000 !important;
  }
  .hidden {
    display: none;
  }
  
  .mt-1rem{
    margin-top: 1rem;
  }