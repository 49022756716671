.card {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    margin: 0 8px 10px 0;
    color:black;
  }
  .card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 9px 12px;
    padding-top: -3px;
  }
  .figure {
    width: 100%;
    border-radius: 24px;
  }
  img {
    width: 90%;
    border-radius: 16px;
    object-fit: cover;
  }
  h3 {
    font-size: 1.5rem;
    font-weight:bold;
  }

  .p-min{
    margin-bottom: -2px;
  }