@media (max-width:900px) {
  .weather-image{
    max-width: 60%;
    align-items: center;
    margin: auto;
  }
}

@media (min-width:900px) {
  .weather-image{
    max-width: 40%;
    align-items: center;
    margin: auto;
  }
}

  .temp{
    font-size: 3.2rem;
  }

  .shadow {
    -webkit-filter: drop-shadow(5px 5px 5px #666666 );
    filter: drop-shadow(5px 5px 5px #666666);
    box-shadow: none !important;
  }

  .c-100 {
      height: 100%;
  }

  .container-card{
    width:100%;
  }